@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

.betterthatBanner {
	font-family: 'Poppins', serif;
	color: #333;
	width: 100vw;
	margin: 0;
	position: relative;
	background-image: url('../../public/assets/home/background.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.2);
		backdrop-filter: blur(13px);
	}

	.bannerContent {
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 1;
		flex: 1;
	}

	.contentWrapper {
		display: flex;
		flex: 1;
		overflow: hidden;
		@media screen and (max-width: 1536px) {
			max-height: 23rem;
		}
		@media screen and (max-width: 1280px) {
			max-height: 18rem;
		}

	}

	.textContent {
		flex: 0 0 67%;
		background: rgba(142, 189, 193, 0.7);
		padding: 2rem 4rem 2rem 4rem;
		overflow-y: auto;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media screen and (max-width: 1536px) {
			max-height: 23rem;
			padding: 1rem 4rem 0rem 4rem;
		}
		@media screen and (max-width: 1280px) {
			max-height: 18rem;
			padding: 1rem 4rem 0rem 4rem;
		}
		@media screen and (max-width: 767px) {

			padding: 2rem 2rem 2rem 2rem;

		}



		h1 {
			color: white;
			font-size: 1.35rem;
			margin-bottom: 1rem;
			@media screen and (max-width: 1536px) {
				font-size: 1.2rem;
			}
			@media screen and (max-width: 1280px) {
				font-size: 1.2rem;
     		}
			//@media screen and (max-width: 767px) {
			//	padding: 1rem;
			//}

		}

		p,
		ul {
			font-size: clamp(1rem, 1.52vw, 1.35rem);
			font-weight: 500;
			line-height: 1.4rem;
			color: black;
			text-align: justify;
			//margin-bottom: 0.15rem;
			@media screen and (max-width: 1536px) {
				font-size: 1rem;
				line-height: .85rem;

			}
			@media screen and (max-width: 1280px) {
				font-size: .9rem;
				line-height: .85rem;

			}

		}

		ul {
			li {
				margin-bottom: 1rem;
			}
		}

		strong {
			font-weight: bold;
		}
	}

	.imageContent {
		flex: 0 0 33%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(255, 255, 255, 0.1); // Add a slight background
		overflow: hidden; // Ensure the image doesn't overflow the rounded corners
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Add a subtle shadow

		img {
			//margin-top: 1rem;
			//object-position: center;
			max-height: 30rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.licenseInfo {
		min-height: 12rem;
		background-color: rgba(67, 89, 91, 0.8);
		backdrop-filter: blur(0px);
		padding: 0.45rem 4rem 1rem 4rem;
		color: #ffffff;
		box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);

		.licenSes {
			margin-bottom: 1rem;
			text-align: justify;
		}
		.licensesEnd {
			text-align: justify;
		}
		span,
		div {
			line-height: 1.4rem;
			font-size: clamp(1rem, 1.52vw, 1.35rem);
			font-weight: 500;
			color: #ffffff;
			margin-bottom: 1.5rem;

		}

		p {
			line-height: 1.4rem;
			font-size: clamp(1rem, 1.52vw, 1.35rem);
			font-weight: 500;
			color: #ffffff;
			margin-bottom: -1rem;
			@media screen and (max-width: 1536px) {
				font-size: .9rem;
				line-height: .85rem;

			}
			@media screen and (max-width: 1280px) {
				font-size: .9rem;
				line-height: .85rem;

			}
		}

		.licenseHolders {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin: 0 0;
			align-items: center;
			@media screen and (max-width: 767px) {
				flex-direction: column;
				gap: 1rem;
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
			}
		}

		.licenseHolder {

			text-align: center;
			flex: 0.1 .1 calc(30% - 1rem); // Adjust flex basis to account for gap
			color: #ffffff;
			max-width: 100%;

			img {
				max-width: 50%;
				max-height: 50%;
				margin: 1rem 0;
			}

			.smallImg {
				max-height: 3rem;

				@media screen and (max-width: 1536px) {
					max-height: 2.5rem;

				}
				@media screen and (max-width: 1280px) {
					max-height: 2.5rem;

				}
			}

			p {
                font-size: 1rem;
				margin: 0rem 0rem -1rem 0rem;
				@media screen and (max-width: 1536px) {
					font-size: .9rem;
					line-height: .85rem;

				}
				@media screen and (max-width: 1280px) {
					font-size: .9rem;
					line-height: .85rem;

				}
			}

			strong {
				font-weight: bold;
			}
		}
	}
}

@media (max-width: 910px) {
	.betterthatBanner {

		.contentWrapper {
			max-height: 100rem;
			flex-direction: column;
		}

		.textContent {
			max-height: 100rem;
			flex: 0 0 auto;
			margin-top: 0;
			p{
				line-height: 1rem;
			}
		}

		.imageContent {

			flex: 0 0 auto;
			flex-direction: column;
			margin-right: 0;
			height: auto;

			img {
				max-height: 30rem;
				object-position: top;
				margin-top: -4rem;
			}
		}

		.imageContent {
			height: auto;
		}

		.licenseInfo {
			padding: 0.45rem 2rem 1rem 2rem; // Reduce padding on smaller screens

			p{
				line-height: 1rem;
			}
		}
	}
}
